import React, { useState, useEffect } from 'react';
import { Row, Button, Form, Alert } from 'react-bootstrap';
import * as constants from '../util/Constants';

export default function CreatePayment(props) {

    const [id, setId] = useState('');
    const [selectedShowId, setSelectedShowId] = useState(0);
    const [paymentAmount, setPaymentAmount] = useState(0);
    const [totalTips, setTotalTips] = useState(0);
    const [comedianPayment, setComedianPayment] = useState(0);

    const [result, setResult] = useState(null);
    const [alertHeading, setAlertHeading] = useState("");
    const [alertText, setAlertText] = useState("");

    useEffect(() => {
        //Setting default showId
        if (props.allShows.length > 0) {
            setSelectedShowId(props.allShows[0].id)
        }
    }, [props.allShows]);

    const createPayment = async () => {
        try {
            let res = await fetch(constants.baseUrl + '/p/ap', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + props.oAuthToken
                },
                body: JSON.stringify({
                    id: id,
                    show: {
                        id: selectedShowId
                    },
                    paymentAmount: paymentAmount,
                    totalTips: totalTips,
                    comedianPayment: comedianPayment
                }),
            });
            if (res.status === 200) {
                setResult("success");
                setAlertHeading("Success!");
                setAlertText("Show was created successfully.")
            } else {
                setResult("danger");
                setAlertHeading("Oops!");
                setAlertText("Something went wrong, show not added.")
            }
        } catch (error) {
            //TODO:
        }
    }

    function clearForm() {
        setSelectedShowId('');
        setPaymentAmount('');
        setTotalTips('');
        setComedianPayment('');
    }

    return (
        <>
            {/* The onChange method for this form is STRICTLY relating to the POST call which would be an update for the selected show data */}
            <h1>Add Payment Details To Show</h1>
            <Form>
                <Row className="mb-3">
                    <Form.Group className="mb-3">

                        <Form.Label>Show</Form.Label>
                        <Form.Select className="mb-3" onChange={(e) => setSelectedShowId(e.target.value)}>
                            {
                                props.allShows.length > 0 ? props.allShows.map((show, index) => (
                                    <option key={show.id} value={show.id}>
                                        {show.showDate} - {show.venue.venueName} - {show.showName}</option>
                                )) : <option>No shows</option>
                            }
                        </Form.Select>

                        <Form.Label>Payment Amount</Form.Label>
                        <Form.Control type="text" onChange={(e) => setPaymentAmount(e.target.value)} value={paymentAmount} />

                        <Form.Label>Total Tips Amount</Form.Label>
                        <Form.Control type="text" onChange={(e) => setTotalTips(e.target.value)} value={totalTips} />

                        <Form.Label>Comedian Payment Amount</Form.Label>
                        <Form.Control type="text" onChange={(e) => setComedianPayment(e.target.value)} value={comedianPayment} />

                    </Form.Group>
                </Row>

                <Button type="button" variant="success" onClick={createPayment}>Submit</Button><br /><br />
                <Button type="button" variant="danger" onClick={clearForm}>CLEAR</Button><br /><br />
                <Row>
                    {result !== null &&
                        <Alert variant={result} onClose={() => setResult(null)} dismissible>
                            <Alert.Heading>{alertHeading}</Alert.Heading>
                            <p>{alertText}</p>
                        </Alert>
                    }
                </Row>
            </Form>
        </>
    )
}