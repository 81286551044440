import React, { useEffect, useState } from 'react'
import { Row, Button, Form, Alert } from 'react-bootstrap'
import * as constants from '../util/Constants'

export default function CreateShow(props) {

    const [showName, setShowName] = useState("")
    const [selectedVenueId, setSelectedVenueId] = useState(0)
    const [showDate, setShowDate] = useState("")
    const [comments, setComments] = useState("")
    const [ticketsLink, setTicketsLink] = useState("")
    const [isPrivateShow, setIsPrivateShow] = useState(false)

    const [result, setResult] = useState(null);
    const [alertHeading, setAlertHeading] = useState("")
    const [alertText, setAlertText] = useState("")

    useEffect(() => {
        //Setting default venueId
        if (props.venues.length > 0) {
            setSelectedVenueId(props.venues[0].id)
        }
    }, [props.venues])

    const createShow = async () => {
        try {
            let res = await fetch(constants.baseUrl + '/s/as', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + props.oAuthToken
                },
                body: JSON.stringify({
                    showName: showName,
                    venue: {
                        id: selectedVenueId
                    },
                    showDate: showDate,
                    comments: comments,
                    ticketsLink: ticketsLink,
                    privateShow: isPrivateShow
                }),
            });
            if (res.status === 200) {
                setResult("success");
                setAlertHeading("Success!");
                setAlertText("Show was created successfully.")
            } else {
                setResult("danger");
                setAlertHeading("Oops!");
                setAlertText("Something went wrong, show not added.")
            }
        } catch (error) {
            //TODO:
        }
    }

    function clearForm() {
        setShowName('')
        setShowDate('')
        setComments('')
        setTicketsLink('')
        setIsPrivateShow(false)
    }

    return (
        <>
            {/* The onChange method for this form is STRICTLY relating to the POST call which would be an update for the selected show data */}
            <h1> Create New Show</h1>
            <Form>
                <Row className="mb-3">
                    <Form.Group className="mb-3">
                        <Form.Label>Show Name</Form.Label>
                        <Form.Control type="text" onChange={(e) => setShowName(e.target.value)} value={showName} />

                        <Form.Label>Venue</Form.Label>
                        <Form.Select className="mb-3" onChange={(e) => setSelectedVenueId(e.target.value)}>
                            {
                                props.venues.length > 0 ? props.venues.map((venue, index) => (
                                    <option key={venue.id} value={venue.id}>{venue.venueName}</option>
                                )) : <option>No venues</option>
                            }
                        </Form.Select>

                        <Form.Label>Show Date</Form.Label>
                        <Form.Control type="text" placeholder="YYYY-MM-DDTHH:MM:SS.000" onChange={(e) => setShowDate(e.target.value)} value={showDate} />

                        <Form.Label>Comments</Form.Label>
                        <Form.Control type="text" onChange={(e) => setComments(e.target.value)} value={comments} />

                        <Form.Label>Tickets Link</Form.Label>
                        <Form.Control type="text" onChange={(e) => setTicketsLink(e.target.value)} value={ticketsLink} />

                        <Form.Check type="checkbox" id={`default-checkbox`} label={`Private Show?`} onChange={(e) => setIsPrivateShow(e.target.checked)} value={isPrivateShow} />
                    </Form.Group>
                </Row>

                <Button type="button" variant="success" onClick={createShow}>Create Show</Button><br /><br />
                <Button type="button" variant="danger" onClick={clearForm}>CLEAR</Button><br /><br />
                <Row>
                    {result !== null &&
                        <Alert variant={result} onClose={() => setResult(null)} dismissible>
                            <Alert.Heading>{alertHeading}</Alert.Heading>
                            <p>{alertText}</p>
                        </Alert>
                    }
                </Row>
            </Form>
        </>
    )
}