import React, { useState } from 'react';
import { Row, Button, Form, Alert } from 'react-bootstrap';
import * as constants from '../util/Constants';

export default function CreateComedian(props) {

    const [id, setId] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [facebook, setFacebook] = useState('');
    const [instagram, setInstagram] = useState('');
    const [twitter, setTwitter] = useState('');
    const [youtube, setYoutube] = useState('');
    const [performanceReferenceUrl, setPerformanceReferenceUrl] = useState('');
    const [location, setLocation] = useState('');
    const [venmo, setVenmo] = useState('');

    const [result, setResult] = useState(null);
    const [alertHeading, setAlertHeading] = useState("");
    const [alertText, setAlertText] = useState("");

    const createComedian = async () => {
        try {
            let res = await fetch(constants.baseUrl + '/cs/ac', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + props.oAuthToken
                },
                body: JSON.stringify({
                    id: id,
                    firstName: firstName,
                    lastName: lastName,
                    facebook: facebook,
                    instagram: instagram,
                    twitter: twitter,
                    youtube: youtube,
                    performanceReferenceUrl: performanceReferenceUrl,
                    location: location,
                    venmo: venmo
                }),
            });
            if (res.status === 200) {
                setResult("success");
                setAlertHeading("Success!");
                setAlertText("Comedian was created successfully.")
            } else {
                setResult("danger");
                setAlertHeading("Oops!");
                setAlertText("Something went wrong, comedian not added.")
            }
        } catch (error) {
            //TODO:
        }
    }

    function handleComedianSelectionClick(event) {

        let chosenComedianId = event.target.value;
        var comedianInfo = props.comedians.find(function (e) {
            return e.id == chosenComedianId;
        });

        //ID should never be blank on an update
        setId(comedianInfo.id);

        comedianInfo.firstName ? setFirstName(comedianInfo.firstName) : setFirstName('')
        comedianInfo.lastName ? setLastName(comedianInfo.lastName) : setLastName('')
        comedianInfo.facebook ? setFacebook(comedianInfo.facebook) : setFacebook('')
        comedianInfo.instagram ? setInstagram(comedianInfo.instagram) : setInstagram('')
        comedianInfo.twitter ? setTwitter(comedianInfo.twitter) : setTwitter('')
        comedianInfo.youtube ? setYoutube(comedianInfo.youtube) : setYoutube('')
        comedianInfo.performanceReferenceUrl ? setPerformanceReferenceUrl(comedianInfo.performanceReferenceUrl) : setPerformanceReferenceUrl('')
        comedianInfo.location ? setLocation(comedianInfo.location) : setLocation('')
        comedianInfo.venmo ? setVenmo(comedianInfo.venmo) : setVenmo('')
    }

    function clearForm() {
        setId('')
        setFirstName('')
        setLastName('')
        setFacebook('')
        setInstagram('')
        setTwitter('')
        setYoutube('')
        setPerformanceReferenceUrl('')
        setLocation('')
        setVenmo('')
    }

    return (
        <>
            <h1> Create / Update Comedians</h1>

            <select style={{ marginBottom: 40 }} onChange={handleComedianSelectionClick}>
                {props.comedians.length > 0 ? props.comedians.map((comedian) => (
                    <option key={comedian.id} value={comedian.id}>
                        {comedian.firstName} {comedian.lastName}
                    </option>
                )) : <option>No comedians in database</option>}
            </select>

            <Form>
                <Row className="mb-3">
                    <Form.Group className="mb-3">

                        <Form.Label>ID</Form.Label>
                        <Form.Control type="text" disabled={true} onChange={(e) => setId(e.target.value)} value={id} />

                        <Form.Label>First Name</Form.Label>
                        <Form.Control type="text" onChange={(e) => setFirstName(e.target.value)} value={firstName} />

                        <Form.Label>Last Name</Form.Label>
                        <Form.Control type="text" onChange={(e) => setLastName(e.target.value)} value={lastName} />

                        <Form.Label>Facebook URL</Form.Label>
                        <Form.Control type="text" onChange={(e) => setFacebook(e.target.value)} value={facebook} />

                        <Form.Label>Instagram URL</Form.Label>
                        <Form.Control type="text" onChange={(e) => setInstagram(e.target.value)} value={instagram} />

                        <Form.Label>Twitter URL</Form.Label>
                        <Form.Control type="text" onChange={(e) => setTwitter(e.target.value)} value={twitter} />

                        <Form.Label>YouTube URL</Form.Label>
                        <Form.Control type="text" onChange={(e) => setYoutube(e.target.value)} value={youtube} />

                        <Form.Label>Performance Reference URL</Form.Label>
                        <Form.Control type="text" onChange={(e) => setPerformanceReferenceUrl(e.target.value)} value={performanceReferenceUrl} />

                        <Form.Label>Location</Form.Label>
                        <Form.Control type="text" onChange={(e) => setLocation(e.target.value)} value={location} />

                        <Form.Label>Venmo</Form.Label>
                        <Form.Control type="text" onChange={(e) => setVenmo(e.target.value)} value={venmo} />
                    </Form.Group>
                </Row>

                <Button type="button" variant="success" onClick={createComedian}>Create / Update Comedian</Button><br /><br />
                <Button type="button" variant="danger" onClick={clearForm}>CLEAR (click if a comedian is selected and you want to add a new comedian)</Button><br /><br />
                <Row>
                    {result !== null &&
                        <Alert variant={result} onClose={() => setResult(null)} dismissible>
                            <Alert.Heading>{alertHeading}</Alert.Heading>
                            <p>{alertText}</p>
                        </Alert>
                    }
                </Row>
            </Form>
        </>
    )
}