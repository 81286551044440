import React, { useContext } from 'react';
import { ShowContext } from '../../../App'

import { Container, Row, Col, Button } from 'react-bootstrap'

import { Link } from "react-router-dom"
import MailingList from '../../side-navbar/MailingList';

export default function UpcomingShows() {

  const { shows, setSelectedShowIndex } = useContext(ShowContext)

  function setSelectedShowInContext(showIndex) {
    //Sets the INDEX in the 'shows' ARRAY (NOT the show.id) to keep track of what to display on the ShowDetails component
    setSelectedShowIndex(showIndex)
  }

  return (
    <>
      <h1 className="text-center">Upcoming Shows</h1>
      <Container fluid>
        {shows.length > 0 ? shows.map((show, index) => (
          <Row key={show.id} style={{ backgroundColor: "black", paddingTop: 5, paddingBottom: 5, marginBottom: 2 }}>
            <Col md={2}>
              <img src={require('../../../assets/img/logo_white.png')}
                style={{ maxWidth: "100%" }} alt="Bus Out Laughing logo" />
            </Col>
            <Col md={8}>
              <h4 className="mt-1">{show.showName} @ {show.venue.venueName}</h4>
              <div>{show.venue.address} {show.venue.city} {show.venue.state} {show.venue.zip} </div>
              <div>{show.displayShowDateTime}</div>
            </Col>
            <Col md={2} style={{ marginTop: 32, marginBottom: 10 }}>
              <Link to="/show-details">
                <div className="d-grid gap-2">
                  <Button variant="primary" size="md" onClick={() => { setSelectedShowInContext(index) }}>
                    <span style={{ padding: 1 }}>
                      Details
                    </span>
                  </Button>
                </div>
              </Link>
            </Col>
          </Row>
        )) : <div className="text-center"><p>No shows are currently scheduled. Follow us on social media, and join our mailing list to
          hear about all future shows!</p><MailingList /></div>
        }
      </Container >
    </>
  )
}