import React, { useState } from 'react';
import { Row, Button, Form, Alert } from 'react-bootstrap';
import * as constants from '../util/Constants';

export default function CreateVenue(props) {

    const [id, setId] = useState('');
    const [venueName, setVenueName] = useState("")
    const [address, setAddress] = useState("")
    const [city, setCity] = useState("")
    const [state, setState] = useState("")
    const [zip, setZip] = useState("")

    const [result, setResult] = useState(null);
    const [alertHeading, setAlertHeading] = useState("");
    const [alertText, setAlertText] = useState("");

    const createVenue = async () => {

        let res = await fetch(constants.baseUrl + '/vs/av', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + props.oAuthToken
            },
            body: JSON.stringify({
                id: id,
                venueName: venueName,
                address: address,
                city: city,
                state: state,
                zip: zip
            }),
        });
        if (res.status === 200) {
            setResult("success");
            setAlertHeading("Success!");
            setAlertText("Show was created successfully.")
        } else {
            setResult("danger");
            setAlertHeading("Oops!");
            setAlertText("Something went wrong, show not added.")
        }
    }

    function handleVenueSelectionClick(event) {

        let chosenVenueId = event.target.value;
        var venueInfo = props.venues.find(function (e) {
            return e.id == chosenVenueId;
        });

        //ID should never be blank on an update
        setId(venueInfo.id);

        venueInfo.venueName ? setVenueName(venueInfo.venueName) : setVenueName('')
        venueInfo.address ? setAddress(venueInfo.address) : setAddress('')
        venueInfo.city ? setCity(venueInfo.city) : setCity('')
        venueInfo.state ? setState(venueInfo.state) : setState('')
        venueInfo.zip ? setZip(venueInfo.zip) : setZip('')
    }

    function clearForm() {
        setId('')
        setVenueName('')
        setAddress('')
        setCity('')
        setState('')
        setZip('')
    }

    return (
        <>
            {/* The onChange method for this form is STRICTLY relating to the POST call which would be an update for the selected venue data */}
            <h1> Create / Update Venues</h1>

            <select style={{ marginBottom: 40 }} onChange={handleVenueSelectionClick}>
                {props.venues.length > 0 ? props.venues.map((venue) => (
                    <option key={venue.id} value={venue.id}>
                        {venue.venueName}
                    </option>
                )) : <option>No venues in database</option>}
            </select>

            <Form>
                <Row className="mb-3">
                    <Form.Group className="mb-3">

                        <Form.Label>ID</Form.Label>
                        <Form.Control type="text" disabled={true} onChange={(e) => setId(e.target.value)} value={id} />

                        <Form.Label>Venue Name</Form.Label>
                        <Form.Control type="text" onChange={(e) => setVenueName(e.target.value)} value={venueName} />

                        <Form.Label>Address</Form.Label>
                        <Form.Control type="text" onChange={(e) => setAddress(e.target.value)} value={address} />

                        <Form.Label>City</Form.Label>
                        <Form.Control type="text" onChange={(e) => setCity(e.target.value)} value={city} />

                        <Form.Label>State</Form.Label>
                        <Form.Control type="text" onChange={(e) => setState(e.target.value)} value={state} />

                        <Form.Label>Zipcode</Form.Label>
                        <Form.Control type="text" onChange={(e) => setZip(e.target.value)} value={zip} />
                    </Form.Group>
                </Row>

                <Button type="button" variant="success" onClick={createVenue}>Create / Update Venue</Button><br /><br />
                <Button type="button" variant="danger" onClick={clearForm}>CLEAR</Button><br /><br />
                <Row>
                    {result !== null &&
                        <Alert variant={result} onClose={() => setResult(null)} dismissible>
                            <Alert.Heading>{alertHeading}</Alert.Heading>
                            <p>{alertText}</p>
                        </Alert>
                    }
                </Row>
            </Form>
        </>
    )
}