import React, { useState } from 'react';
import { Buffer } from 'buffer';

import { Container, Row, Form, Button, Accordion } from 'react-bootstrap';
import AccordionBody from 'react-bootstrap/esm/AccordionBody';
import AccordionItem from 'react-bootstrap/esm/AccordionItem';
import AccordionHeader from 'react-bootstrap/esm/AccordionHeader';

import * as constants from '../util/Constants';
import CreateShow from './CreateShow';
import UpdateShow from './UpdateShow';
import CreateComedian from './CreateComedian';
import CreateVenue from './CreateVenue';
import CreatePayments from './CreatePayment';

export default function Console() {

    const [comedians, setComedians] = useState([]);
    const [venues, setVenues] = useState([]);
    const [allShows, setAllShows] = useState([]);

    const [clientId, setClientId] = useState('');
    const [clientSecret, setClientSecret] = useState('');

    const [oAuthToken, setOAuthToken] = useState(null);

    const getToken = async () => {

        let encodedClientAuth = Buffer.from(clientId + ':' + clientSecret).toString('base64');

        var details = {
            'grant_type': 'client_credentials'
        };

        var formBody = [];
        for (var property in details) {
            var encodedKey = encodeURIComponent(property);
            var encodedValue = encodeURIComponent(details[property]);
            formBody.push(encodedKey + "=" + encodedValue);
        }
        formBody = formBody.join("&");

        fetch(constants.baseUrl + '/oauth/token', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                'Authorization': 'Basic ' + encodedClientAuth
            },
            body: formBody
        })
            .then((response) => response.json())
            .then((data) => setOAuthToken(data.access_token))
            .catch((error) => { }) //TODO
    }

    function getData() {
        getComedians();
        getVenues();
        getShows();
    }

    function getComedians() {
        fetch(constants.baseUrl + '/cs/fa', {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + oAuthToken
            }
        })
            .then((response) => response.json())
            .then((data) => setComedians(data))
            .catch((error) => { }) //TODO
    }

    const getVenues = async () => {
        await fetch(constants.baseUrl + "/vs/fa", {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + oAuthToken
            }
        })
            .then((response) => response.json())
            .then((data) => setVenues(data))
            .catch((error) => { }) //TODO
    }

    const getShows = async () => {
        await fetch(constants.baseUrl + "/s/shows/all", {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + oAuthToken
            }
        })
        .then((response) => response.json())
        .then((data) => setAllShows(data))
    }

    return (
        <Container fluid className="text-light p-5" style={{ backgroundColor: "#3c0c33" }}>
            <Row style={{ backgroundColor: "#2b1626", padding: 10, marginBottom: 25 }}>

                <Form>
                    <Row className="mb-3">
                        <Form.Group className="mb-3">
                            <Form.Control type="password" onChange={(e) => setClientId(e.target.value)} value={clientId} />
                            <Form.Control type="password" onChange={(e) => setClientSecret(e.target.value)} value={clientSecret} />
                        </Form.Group>
                        <Button type="button" variant="success" onClick={getToken}>Go</Button><br /><br />
                        <Button type="button" variant="success" onClick={getData}>Get Data</Button><br /><br />
                    </Row>
                </Form>

                <Accordion defaultActiveKey={['0']}>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>Create Comedians</Accordion.Header>
                        <AccordionBody>
                            <CreateComedian comedians={comedians} oAuthToken={oAuthToken} />
                        </AccordionBody>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                        <Accordion.Header>Create Venues</Accordion.Header>
                        <AccordionBody>
                            <CreateVenue venues={venues} oAuthToken={oAuthToken} />
                        </AccordionBody>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                        <Accordion.Header>Create Shows</Accordion.Header>
                        <AccordionBody>
                            <CreateShow venues={venues} oAuthToken={oAuthToken} />
                        </AccordionBody>
                    </Accordion.Item>
                    <AccordionItem eventKey='3'>
                        <AccordionHeader>Update Shows</AccordionHeader>
                        <AccordionBody>
                            <UpdateShow comedians={comedians} venues={venues} allShows={allShows} oAuthToken={oAuthToken} />
                        </AccordionBody>
                    </AccordionItem>
                    <AccordionItem eventKey='4'>
                        <AccordionHeader>Payments</AccordionHeader>
                        <AccordionBody>
                            <CreatePayments allShows={allShows} oAuthToken={oAuthToken} />
                        </AccordionBody>
                    </AccordionItem>
                </Accordion>
            </Row >
        </Container >
    )
}