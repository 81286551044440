import React, { useState } from 'react';
import { Container, Row, Button, Modal, Form, Alert } from 'react-bootstrap'
import * as constants from '../util/Constants';

export default function MailingList() {

  const [email, setEmail] = useState("");

  const [showMailListModal, setShowMailListModal] = useState(false);
  const handleClose = () => setShowMailListModal(false);
  const handleShow = () => setShowMailListModal(true);

  const [result, setResult] = useState(null);
  const [alertHeading, setAlertHeading] = useState("");
  const [alertText, setAlertText] = useState("");

  const handleSubmit = (event) => {
    submitEmailForMailListSignUp()
  }

  const submitEmailForMailListSignUp = async () => {
    try {
      let res = await fetch(constants.baseUrl + '/mls/atml', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          email: email
        }),
      });
      if (res.status === 200) {
        setResult("success");
        setAlertHeading("Success!");
        setAlertText("You have successfully signed up!")
      } else {
        setResult("danger");
        setAlertHeading("Oops!");
        setAlertText("Sorry, something went wrong.  Please try submitting your email again.")
      }
    } catch (error) {
      setResult("danger");
      setAlertHeading("Oops!");
      setAlertText("Sorry, something went wrong.  Please try submitting your email again.")
    }
  }

  return (
    <>
      <Button variant="success" onClick={handleShow}><div>Join Mailing List</div></Button>

      <Modal
        show={showMailListModal}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Mailing List Sign Up</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Submit your email to stay up to date with the latest news and show information!</p>
          <Form>
            <Row className="mb-3">
              <Form.Group className="mb-3" controlId="validationCustom02">
                <Form.Label>Email</Form.Label>
                <Form.Control type="email" onChange={(e) => setEmail(e.target.value)} />
              </Form.Group>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" type="button" onClick={handleSubmit}>Submit</Button><br /><br />
          <Container>
            <Row>
              {result !== null &&
                <Alert variant={result} onClose={() => setResult(null)} dismissible>
                  <Alert.Heading>{alertHeading}</Alert.Heading>
                  <p>{alertText}</p>
                </Alert>
              }
            </Row>
          </Container>
        </Modal.Footer>
      </Modal>
    </>
  )
}