import { HashLink } from "react-router-hash-link"
import { Link } from "react-router-dom"
import { Container, Row, Col, Button } from 'react-bootstrap';

import ColoredLine from "../util/ColoredLine"
import MailingList from './MailingList';

export default function Navbar() {

  return (
    <Container>
      <Row>
        <Col>
          <div style={{ color: "white" }}>
            <Link to="/">
              <img src={require('../../assets/img/logo_white.png')}
                style={{ maxWidth: "100%" }} alt="Bus Out Laughing logo" />
            </Link>
            <div className='text-center'><small>The first and only mobile comedy club hosted out of a 1973 VW Bus!</small></div>
            <ColoredLine color="white" />

            <div className="d-grid gap-1">
              <HashLink to="/#top">
                <div className="d-grid">
                  <Button variant="primary" size="lg" style={{ fontSize: "15px" }}>Home</Button>
                </div>
              </HashLink>
              <HashLink to="show-details/#top">
                <div className="d-grid">
                  <Button variant="primary" size="lg" style={{ fontSize: "15px" }}>Show Details</Button>
                </div>
              </HashLink>
              <HashLink smooth to="our-story/#top">
                <div className="d-grid">
                  <Button variant="primary" size="lg" style={{ fontSize: "15px" }}>Our Story</Button>
                </div>
              </HashLink>
              <Button variant="success" href="https://forms.gle/6o4x9D5VHXfzMLo99" size="lg" style={{ fontSize: "15px" }}
                target='_blank' rel="noreferrer">Book Us!
              </Button>
            </div>

            <ColoredLine color="white" />
            <div className='text-center'><small>Follow us on Social Media</small></div>
            <ColoredLine color="white" />


            <a href="https://www.facebook.com/busoutlaughing" target='_blank' rel="noreferrer">
              <img src={require('../../assets/img/social/new/facebook.png')}
                style={{ maxWidth: "25%", padding: 5 }} alt="Facebook link" />
            </a>

            <a href="https://www.twitter.com/busoutlaughing" target='_blank' rel="noreferrer">
              <img src={require('../../assets/img/social/new/twitter.png')}
                style={{ maxWidth: "25%", padding: 5 }} alt="Twitter link" />
            </a>


            <a href="https://www.youtube.com/channel/UChBQXR5RDNnjWt2sazrStvQ" target='_blank' rel="noreferrer">
              <img src={require('../../assets/img/social/new/youtube.png')}
                style={{ maxWidth: "25%", padding: 5 }} alt="Youtube link" />
            </a>


            <a href="https://www.instagram.com/busoutlaughing" target='_blank' rel="noreferrer">
              <img src={require('../../assets/img/social/new/instagram.png')}
                style={{ maxWidth: "25%", padding: 5 }} alt="Instagram link" />
            </a>

            <div className="d-grid gap-1">
              <div className="d-grid">
                <MailingList />
              </div>
              <div className="d-grid">
                <Button variant="success" href="mailto:busoutlaughing@gmail.com">
                  Contact Us
                </Button>
              </div>
            </div>
            <ColoredLine color="white" />

            <a href="https://www.yelp.com/writeareview/biz/plqlaRGuJs-Mcg36OlGutA" target='_blank' rel="noreferrer">
              <img src={require('../../assets/img/yelp.png')}
                style={{ maxWidth: "100%", padding: 5 }} alt="Yelp Review Link" />
            </a>

            <ColoredLine color="white" />
          </div>
        </Col>
      </Row>
    </Container>
  )
}