import { useContext } from 'react'
import { ShowContext } from '../../App'

import { Container, Row, Col, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import ColoredLine from '../util/ColoredLine';
import { BsClipboard } from 'react-icons/bs'
import MailingList from '../side-navbar/MailingList';

export default function ShowDetails() {

  const { shows, selectedShowIndex, setSelectedShowIndex } = useContext(ShowContext)

  let fullNameAndAddress = "";
  if (shows.length > 0) {
    fullNameAndAddress = shows[selectedShowIndex].venue.venueName + " " + shows[selectedShowIndex].venue.address + " " + shows[selectedShowIndex].venue.city
      + " " + shows[selectedShowIndex].venue.state + " " + shows[selectedShowIndex].venue.zip
  }

  function handleShowSelectionClick(event) {
    setSelectedShowIndex(event.target.value)
  }

  return (
    <Container fluid className="text-light p-5" style={{ backgroundColor: "#3c0c33" }}>
      <Row style={{ backgroundColor: "#2b1626", padding: 1, marginBottom: 25 }}>

        {shows.length > 0 &&
          <>
            <h3 className="text-center" id="top">All Upcoming Shows</h3>
            <select style={{ marginBottom: 40 }} onChange={handleShowSelectionClick}>
              {shows.length > 0 ? shows.map((show, index) => (
                <option key={show.id} value={index}>
                  {show.showName} - {show.venue.venueName} - {show.displayShowDateTime}
                </option>
              )) : <option>No shows are scheduled yet, check back again soon.</option>}
            </select>

            <Col md={6}>
              <h3>Show Details</h3>
              <ColoredLine color="white" />

              <p>{shows[selectedShowIndex].showName} @ {shows[selectedShowIndex].venue.venueName}</p>

              <p style={{ marginBottom: 40 }}>{shows[selectedShowIndex].displayShowDateTime}</p>

              <p style={{ marginBottom: 40 }}>{shows[selectedShowIndex].comments}</p>

              {shows[selectedShowIndex].ticketsLink ? <p style={{ marginBottom: 40 }}>
                <Button variant="primary" size="lg" target="_blank" href={shows[selectedShowIndex].ticketsLink}>RESERVE TICKETS</Button> </p>
                : <></>}

              <h3>Venue Info</h3>
              <ColoredLine color="white" />

              <p>{shows[selectedShowIndex].venue.venueName}</p>
              <Row>
                <Col md={10}>
                  <p>{shows[selectedShowIndex].venue.address} {shows[selectedShowIndex].venue.city} {shows[selectedShowIndex].venue.state} {shows[selectedShowIndex].venue.zip}</p>
                </Col>
                <Col md={2} style={{ display: "inline" }}>
                  <OverlayTrigger key="top" placement="top" overlay={<Tooltip>
                    Copy venue information to clipboard
                  </Tooltip>}
                  >
                    <Button variant="light" onClick={() => { navigator.clipboard.writeText(fullNameAndAddress); }}><BsClipboard /></Button>
                  </OverlayTrigger>
                </Col>
                <p style={{ marginBottom: 40 }}>
                  <a href={"https://google.com/maps/search/" + shows[selectedShowIndex].venue.venueName}
                    target="_blank" rel="noreferrer">Click here for directions to the venue</a>
                </p>
              </Row>
            </Col>

            <Col md={6}>
              <h3>Comedians / Social Media Links</h3>
              <ColoredLine color="white" />

              {shows[selectedShowIndex].comedians.length > 0 ? shows[selectedShowIndex].comedians.map(comedian => (
                <Row>
                  <p>{comedian.firstName} {comedian.lastName}</p>
                  <div>
                    {comedian.facebook &&
                      <a href={comedian.facebook} target='_blank' rel="noreferrer">
                        <img className='img-fluid' src={require('../../assets/img/social/new/facebook_small.png')} style={{ padding: 5 }} alt="Comedian Facebook link" />
                      </a>}
                    {comedian.instagram &&
                      <a href={comedian.instagram} target='_blank' rel="noreferrer">
                        <img className='img-fluid' src={require('../../assets/img/social/new/instagram_small.png')} style={{ padding: 5 }} alt="Comedian Instagram link" />
                      </a>}
                    {comedian.youtube &&
                      <a href={comedian.youtube} target='_blank' rel="noreferrer">
                        <img className='img-fluid' src={require('../../assets/img/social/new/youtube_small.png')} style={{ padding: 5 }} alt="Comedian YouTube link" />
                      </a>}
                    {comedian.twitter &&
                      <a href={comedian.twitter} target='_blank' rel="noreferrer">
                        <img className='img-fluid' src={require('../../assets/img/social/new/twitter_small.png')} style={{ padding: 5 }} alt="Comedian Twitter link" />
                      </a>}
                    <ColoredLine color="white" />
                  </div>
                </Row>
              )) : <div>Line up for this show will be provided soon!</div>
              }
            </Col>
          </>
        }

        {
          shows.length < 1 &&
          <div className="text-center">
            <p id="top">
              No shows are currently scheduled at the moment but consider joining our mailing list to get the latest show information
            </p>
            <MailingList />
            <br /><br />
          </div>
        }
      </Row>
    </Container>
  )
}