import React, { createContext, useEffect, useState } from 'react'

import { Container, Row, Col } from 'react-bootstrap'
import { Route, Routes } from "react-router-dom"

import * as constants from './components/util/Constants';
import Navbar from "./components/side-navbar/Navbar"
import Home from "./components/home/Home"
import ShowDetails from "./components/show-details/ShowDetails"
import CommentCard from "./components/comment-card/CommentCard"
import OurStory from "./components/our-story/OurStory"
import Console from "./components/console/Console"

import './index.css'
import 'bootstrap/dist/css/bootstrap.min.css'

export const ShowContext = createContext([])

export default function App() {

  const [shows, setShows] = useState([])
  const [selectedShowIndex, setSelectedShowIndex] = useState('')

  useEffect(() => {
    getFutureShows()
  }, []);

  const getFutureShows = async () => {
    fetch(constants.baseUrl + "/s/shows", {
      method: 'GET'
    })
      .then((response) => response.json())
      .then((data) => {
        setShows(data)
        //Default the selected show to be the first one in the list
        setSelectedShowIndex(0)
      })
      .catch((err) => { }) //TODO
  }

  return (
    <ShowContext.Provider value={{ shows, setShows, selectedShowIndex, setSelectedShowIndex }}>
      <Container fluid>
        <Row>
          <Col md={2} style={{ margin: 0 }}>
            <Navbar />
          </Col>
          <Col md={10} style={{ display: 'inline', padding: 0 }}>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/show-details" element={<ShowDetails />} />
              <Route path="/comment-card" element={<CommentCard />} />
              <Route path="/our-story" element={<OurStory />} />
              <Route path="/supersleuthycontrolboard" element={<Console />} />
            </Routes>
          </Col>
        </Row>
      </Container>
    </ShowContext.Provider>
  )
}


