import React, { useState, useEffect } from 'react'
import { useContext } from 'react'
import { ShowContext } from '../../App'
import * as constants from '../util/Constants'

import { Container, Row, Col, Button, Form, Table, Alert } from 'react-bootstrap'

export default function UpdateShow(props) {

    const [selectedShowIndex, setSelectedShowIndex] = useState(0)
    const [historicalShows, setHistoricalShows] = useState([])

    const [showId, setShowId] = useState("")
    const [showName, setShowName] = useState("")
    const [venueId, setVenueId] = useState("")
    const [showDate, setShowDate] = useState("")
    const [comments, setComments] = useState("")
    const [ticketsLink, setTicketsLink] = useState("")
    const [isPrivateShow, setIsPrivateShow] = useState(false)

    const [comedianId, setComedianId] = useState("")
    const [sequenceNumber, setSequenceNumber] = useState("")

    const [result, setResult] = useState(null)
    const [alertHeading, setAlertHeading] = useState("")
    const [alertText, setAlertText] = useState("")

    const updateShow = async () => {
        try {
            let res = await fetch(constants.baseUrl + '/s/as', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + props.oAuthToken
                },
                body: JSON.stringify({
                    id: showId,
                    showName: showName,
                    venue: {
                        id: venueId
                    },
                    showDate: showDate,
                    comments: comments,
                    ticketsLink: ticketsLink,
                    privateShow: isPrivateShow
                }),
            })
            if (res.status === 200) {
                setResult("success")
                setAlertHeading("Success!")
                setAlertText("Show was updated successfully.")
            } else {
                setResult("danger")
                setAlertHeading("Oops!")
                setAlertText("Something went wrong, show not updated.")
            }
        } catch (error) {
            //TODO:
        }
    }

    const removeComedianFromShow = async (event) => {
        try {
            let res = await fetch(constants.baseUrl + '/s/rcfs', {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + props.oAuthToken
                },
                body: JSON.stringify({
                    comedianId: event.target.value,
                    showId: showId
                }),
            })
            if (res.status === 200) {
                setResult("success")
                setAlertHeading("Success!")
                setAlertText("Comedian was removed successfully.")
            } else {
                setResult("danger")
                setAlertHeading("Oops!")
                setAlertText("Something went wrong.")
            }
        } catch (error) {
            //TODO:
        }
    }

    const addComedianToShow = async (event) => {
        try {
            let res = await fetch(constants.baseUrl + '/s/acts', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + props.oAuthToken
                },
                body: JSON.stringify({
                    comedianId: comedianId,
                    showId: showId,
                    sequenceNumber: sequenceNumber
                }),
            })
            if (res.status === 200) {
                setResult("success")
                setAlertHeading("Success!")
                setAlertText("Comedian was added successfully.")
            } else {
                setResult("danger")
                setAlertHeading("Oops!")
                setAlertText("Something went wrong.")
            }
        } catch (error) {
            //TODO:
        }
    }

    const getRecentShowDataForVenue = async (venueId) => {
        await fetch(constants.baseUrl + '/s/flt/' + venueId, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + props.oAuthToken
            }
        })
            .then((response) => response.json())
            .then((data) => { setHistoricalShows(data) })
            .catch((err) => { }) //TODO
    }

    function handleShowSelectionClick(event) {
        let i = event.target.value

        setSelectedShowIndex(i)

        setShowId(props.allShows[i].id)
        setShowName(props.allShows[i].showName)
        setVenueId(props.allShows[i].venue.id)
        setShowDate(props.allShows[i].showDate)
        setComments(props.allShows[i].comments)
        setTicketsLink(props.allShows[i].ticketsLink)
        setIsPrivateShow(props.allShows[i].privateShow)

        //Get recent show data after selecting a venue from dropdown list to help in booking comics
        getRecentShowDataForVenue(props.allShows[i].venue.id)
    }

    function handleComedianSelectionClick(event) {
        setComedianId(event.target.value)
    }

    return (
        <>
            {/* The onChange method for this form is STRICTLY relating to the POST call which would be an update for the selected show data */}
            <Container fluid>
                <Row>
                    <Col>
                        <h1> Update Existing Show</h1>

                        <select style={{ marginBottom: 40 }} onChange={handleShowSelectionClick}>
                            <option value={0}>Default Show</option>
                            {props.allShows.length > 0 ? props.allShows.map((show, index) => (
                                <option key={show.id} value={index}>
                                    {show.showName} - {show.venue.venueName} - {show.displayShowDateTime}
                                </option>
                            )) : <option>No shows in database</option>}
                        </select>

                        <Form>
                            <Row className="mb-3">
                                <Form.Group className="mb-3">
                                    <Form.Label>Show ID</Form.Label>
                                    <Form.Control type="text" disabled={true} onChange={(e) => setShowId(e.target.value)} value={showId} />

                                    <Form.Label>Show Name</Form.Label>
                                    <Form.Control type="text" onChange={(e) => setShowName(e.target.value)} value={showName} />

                                    <Form.Label>Venue ID</Form.Label>
                                    <Form.Control type="text" disabled={true} onChange={(e) => setVenueId(e.target.value)} value={venueId} />

                                    <Form.Label>Show Date</Form.Label>
                                    <Form.Control type="text" placeholder="YYYY-MM-DDTHH:MM:SS.000" onChange={(e) => setShowDate(e.target.value)} value={showDate} />

                                    <Form.Label>Comments</Form.Label>
                                    <Form.Control type="text" onChange={(e) => setComments(e.target.value)} value={comments} />

                                    <Form.Label>Tickets Link</Form.Label>
                                    <Form.Control type="text" onChange={(e) => setTicketsLink(e.target.value)} value={ticketsLink} />

                                    <Form.Check type="checkbox" id={`default-checkbox`} label={`Private Show?`} onChange={(e) => setIsPrivateShow(e.target.checked)} value={isPrivateShow} checked={isPrivateShow} />
                                </Form.Group>
                            </Row>
                            <Button type="button" variant="success" onClick={updateShow}>Update Show</Button><br /><br />
                        </Form>


                        <Row>
                            {result !== null &&
                                <Alert variant={result} onClose={() => setResult(null)} dismissible>
                                    <Alert.Heading>{alertHeading}</Alert.Heading>
                                    <p>{alertText}</p>
                                </Alert>
                            }
                        </Row>
                    </Col>
                    <Col>
                        <h1>Historical Show Data</h1>
                        {
                            historicalShows.length > 0 ? historicalShows.map((show) => (
                                <>
                                    <h5>{show.venue.venueName} @ {show.showDate}</h5>
                                    <Table striped bordered hover variant="dark">
                                        <thead>
                                            <tr>
                                                <th>ID</th>
                                                <th>First Name</th>
                                                <th>Last Name</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                show.comedians.map(comedian => (
                                                    <tr>
                                                        <td>{comedian.id}</td>
                                                        <td>{comedian.firstName}</td>
                                                        <td>{comedian.lastName}</td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </Table>
                                </>
                            )) : <tr>None</tr>
                        }

                    </Col>
                </Row>
            </Container>

            <Row>
                <h1> Add / Remove Comedian From Show</h1>
                <Table striped bordered hover variant="dark">
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>First Name</th>
                            <th>Last Name</th>
                            <th>Delete</th>
                        </tr>
                    </thead>
                    <tbody>
                        {props.allShows.length > 0 && props.allShows[selectedShowIndex].comedians.length > 0 ? props.allShows[selectedShowIndex].comedians.map(comedian => (
                            <tr key={comedian.id}>
                                <td>{comedian.id}</td>
                                <td>{comedian.firstName}</td>
                                <td>{comedian.lastName}</td>
                                <td><Button type="button" variant="danger" value={comedian.id} onClick={e => removeComedianFromShow(e)}>Delete</Button></td>
                            </tr>
                        )) : <tr><td colSpan={4}>No one has been added to the show yet</td></tr>
                        }
                    </tbody>
                </Table>
                <Row>
                    <Col md={5}>
                        <select style={{ marginBottom: 40 }} onChange={handleComedianSelectionClick}>
                            {props.comedians.length > 0 ? props.comedians.map((comedian) => (
                                <option key={comedian.id} value={comedian.id}>
                                    {comedian.firstName} {comedian.lastName}
                                </option>
                            )) : <option>No comedians in database</option>}
                        </select>
                    </Col>
                    <Col>
                        <Form>
                            <Row className="mb-3">
                                <Form.Group className="mb-3">
                                    <Form.Control type="text" placeholder="Seq. Number" onChange={(e) => setSequenceNumber(e.target.value)} value={sequenceNumber} />
                                </Form.Group>
                            </Row>
                        </Form>
                    </Col>
                    <Col md={5}>
                        <Button type="button" variant="success" onClick={addComedianToShow}>Add Comedian To Show</Button><br /><br />
                    </Col>
                </Row>
            </Row>
        </>
    )
}