import { Row, Col, Button } from 'react-bootstrap';

export default function WhatWeOffer() {
  return (
    <>
      <h1 className="text-center">What We Offer</h1>
      <Row>
        <Col md={8}>
          <div className="ratio ratio-16x9" style={{ marginBottom: 15 }}>
            <iframe src="https://youtube.com/embed/VQy6TCYUkOk" title="Bus Out Laughing Promotional Trailer" allowFullScreen></iframe>
          </div>
        </Col>
        <Col md={4}>
          <p>
            We bring the <strong>best comedians</strong> the Bay Area has to offer, <strong>right to you</strong>!
            We work with a large diverse group of professional comedians and guarantee to bring the entertainment
            with a <strong>unique and curated comedy experience</strong> for each event. We are flexible to fit
            your needs for your:
          </p>
          <ul>
            <li><strong>Brewery/Winery</strong> or <strong>Restaurant/Pub</strong></li>
            <li><strong>Bachelor/Bachelorette Party</strong></li>
            <li><strong>Family gathering/Birthday Party</strong></li>
            <li><strong>Festivals/Outdoor Events</strong></li>
          </ul>
          <p>
            ..and the list goes on! You name it, we have you covered with live entertainment.
          </p>
          <p>
            Our mobile show is completely self powered which allows us to set up a performance virtually
            anywhere. Our setup can include the following items (or a subset of them if available space is limited):
          </p>
          <ul>
            <li><strong>Portable Stage</strong> (4ft Deep x 8ft Wide)</li>
            <li><strong>Adjustable Curtain Backdrop</strong></li>
            <li><strong>PA System</strong> (2x Wired Speakers)</li>
            <li><strong>Smoke Machines</strong> (Yep! We go all out!)</li>
            <li><strong>1973 VW Baywindow Bus</strong></li>
          </ul>
          <p>
            We love to work with businesses big and small and <strong>will increase your sales</strong> by
            drawing in a crowd. It's a <strong>win-win situation</strong> and will allow for cross-promotion of
            our business and yours. Our services are available anywhere in the greater San Francisco Bay Area
            but can travel further upon request. Book confidently with us today for your next event!
          </p>
          <div className="text-center">
            <a href="https://forms.gle/6o4x9D5VHXfzMLo99" target="_blank" rel="noreferrer">
              <Button variant="success" size="md" style={{ marginBottom: 20 }}><div style={{ padding: 1, fontSize: "15px" }}>Book with us now!</div></Button>
            </a>
          </div>
        </Col>
      </Row>
    </>
  )
}