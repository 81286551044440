import Carousel from 'react-bootstrap/Carousel';

export default function PhotoCarousel() {
  return (
    <Carousel fade style={{ paddingBottom: 15 }}>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={require("../../assets/img/outside-setup.png")}
          alt="Our self-powered outdoor setup at a house party"
        />
        <Carousel.Caption>
          <p>Our self-powered outdoor setup at a house party</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={require("../../assets/img/outside-audience.png")}
          alt="We pack the venues we work with"
        />
        <Carousel.Caption>
          <p>We pack the venues we work with</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={require("../../assets/img/indoor-kristina.png")}
          alt="Comedian Kristina Pandis performing at Joes Corner - Fremont, CA"
        />
        <Carousel.Caption>
          <p>Comedian Kristina Pandis performing at Joes Corner - Fremont, CA</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={require("../../assets/img/outside-setup2.png")}
          alt="The Mobile Comedy Club"
        />
        <Carousel.Caption>
          <p>The Mobile Comedy Club</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={require("../../assets/img/outside-setup3.png")}
          alt="The Mobile Comedy Club"
        />
        <Carousel.Caption>
          <p>The Mobile Comedy Club</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={require("../../assets/img/bol-logo-stage.png")}
          alt="Our logo on our custom portable stage"
        />
        <Carousel.Caption>
          <p>Our logo on our custom portable stage</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={require("../../assets/img/justin.JPG")}
          alt="Hosting the show"
        />
        <Carousel.Caption>
          <p>Hosting the show</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={require("../../assets/img/robert.JPG")}
          alt="Comedian Robert Vogel"
        />
        <Carousel.Caption>
          <p>Comedian Robert Vogel performing @ Das Brew - Fremont, CA</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={require("../../assets/img/jeff.JPG")}
          alt="Comedian Jeff Morris performing @ Das Brew - Fremont, CA"
        />
        <Carousel.Caption>
          <p>Comedian Jeff Morris performing @ Das Brew - Fremont, CA</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={require("../../assets/img/dan.JPG")}
          alt="Comedian Dan Tran"
        />
        <Carousel.Caption>
          <p>Comedian Dan Tran performing @ Das Brew - Fremont, CA</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={require("../../assets/img/coral.jpg")}
          alt="Comedian Coral Best"
        />
        <Carousel.Caption>
          <p>Comedian Coral Best performing @ Das Brew - Fremont, CA</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={require("../../assets/img/nick.JPG")}
          alt="Comedian Nick Makar"
        />
        <Carousel.Caption>
          <p>Comedian Nick Makar headlining @ Das Brew - Fremont, CA</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={require("../../assets/img/setup.jpg")}
          alt="Our outdoor setup at Das Brew in Fremont, CA"
        />
        <Carousel.Caption>
          <p>An outdoor show @ Das Brew - Fremont, CA</p>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  )
}