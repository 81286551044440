import { Container, Row, Col } from 'react-bootstrap';

import PhotoCarousel from './PhotoCarousel';
import WhatWeOffer from './WhatWeOffer';
import UpcomingShows from './upcoming-shows/UpcomingShows'

export default function Home(props) {

  return (
    <Container fluid className="text-light p-5" style={{ backgroundColor: "#3c0c33" }}>
      <Row style={{ backgroundColor: "#2b1626", padding: 1, marginBottom: 25 }}>
        <Col md={6} id="top">
          <UpcomingShows shows={props} />
        </Col>
        <Col md={6}>
          <h1 className="text-center">Gallery</h1>
          <PhotoCarousel />
        </Col>
      </Row>
      <Row style={{ backgroundColor: "#2b1626", padding: 1, marginBottom: 25 }}>
        <WhatWeOffer />
      </Row>
    </Container>
  )
}