import React, { useState } from 'react'
import { Container, Row, Button, Form, Alert } from 'react-bootstrap'
import * as constants from '../util/Constants';

export default function CommentCard() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [whereHeardAbout, setWhereHeardAbout] = useState("");
  const [comments, setComments] = useState("");
  const [signUpForMailList, setSignUpForMailList] = useState(false);
  const [result, setResult] = useState(null);
  const [alertHeading, setAlertHeading] = useState("");
  const [alertText, setAlertText] = useState("");

  const handleMailListSignUp = (event) => {
    setSignUpForMailList(event.target.checked)
  }

  const handleSubmit = (event) => {
      submitCommentCard()
  }

  const submitCommentCard = async () => {

    try {

      let res = await fetch(constants.baseUrl + '/cards', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          fullName: name,
          email: email,
          whereHeardAbout: whereHeardAbout,
          comments: comments,
          signUpForMailList: signUpForMailList
        }),
      });
      if (res.status === 200) {
        setResult("success");
        setAlertHeading("Success!");
        setAlertText("Thanks for submitting your comment card!  We value your opinion and feedback.")
      } else {
        setResult("danger");
        setAlertHeading("Oops!");
        setAlertText("Sorry, something went wrong.  Please try submitting your comments again.")
      }
    } catch (error) {
      setResult("danger");
      setAlertHeading("Oops!");
      setAlertText("Sorry, something went wrong.  Please try submitting your comments again.")
    }
  }

  return (
    <Container fluid className="text-light p-5" style={{ backgroundColor: "#3c0c33" }}>
      <Row style={{ backgroundColor: "#2b1626", padding: 10, marginBottom: 25 }}>

        <div className="text-center" style={{ padding: 20 }}>
          <h1 id="top">Comment Cards</h1>
          <h5>Fill out a comment card for a chance to win a raffle prize</h5>
        </div>

        <Form>
          <Row className="mb-3">
            <Form.Group className="mb-3" controlId="validationCustom01">
              <Form.Label>Full Name</Form.Label>
              <Form.Control type="text" onChange={(e) => setName(e.target.value)} />
            </Form.Group>

            <Form.Group className="mb-3" controlId="validationCustom02">
              <Form.Label>Email</Form.Label>
              <Form.Control type="email" onChange={(e) => setEmail(e.target.value)} />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>How Did You Hear About Us?</Form.Label>
              <Form.Control type="text" onChange={(e) => setWhereHeardAbout(e.target.value)} />
            </Form.Group>

            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
              <Form.Label>Comments/Suggestions</Form.Label>
              <Form.Control as="textarea" rows={3} onChange={(e) => setComments(e.target.value)} />
            </Form.Group>
          </Row>

          <Form.Group className="mb-3">
            <Form.Check type="checkbox" label="Signup for Mailing List" onChange={handleMailListSignUp} />
          </Form.Group>

          <Button type="button" onClick={handleSubmit}>Submit</Button><br /><br />
          {result !== null &&
            <Alert variant={result} onClose={() => setResult(null)} dismissible>
              <Alert.Heading>{alertHeading}</Alert.Heading>
              <p>{alertText}</p>
            </Alert>
          }
        </Form>

      </Row>
    </Container>
  )
}