import { Container, Row, Col, Button } from 'react-bootstrap';
import ColoredLine from "../util/ColoredLine"

export default function OurStory() {
  return (
    <Container fluid className="text-light p-5" style={{ backgroundColor: "#3c0c33" }}>
      <Row style={{ backgroundColor: "#2b1626", padding: 10, marginBottom: 25 }}>

        <div className="text-center" style={{ padding: 20 }}>
          <h1 id="top">Our Story</h1>
          <h4>The journey of how we made it happen..</h4>
        </div>

        <Row style={{ padding: 20 }}>
          <Col md={4}>
            <img className="rounded" src={require('../.././assets/img/haul.jpg')}
              style={{ maxWidth: "100%", marginBottom: 15 }} alt="The bus when we were hauling it home!" />
          </Col>
          <Col md={8}>
            <h4>The Dream</h4>
            <ColoredLine color="white" />
            <p>
              For numerous years now, the dream of owning a Volkswagen Bus was alive and real. Over that timeframe
              we researched as much information as we could about these vehicles while keeping a close eye on
              Craigslist, TheSamba, and Facebook seeking the right one. Luckily there were always choices, but
              none seemed to work out for one reason or another. It is a 50+ year old vehicle after all! The
              plan was to obtain a bus, and take it out camping, go on road trips and travel, and create some
              great memories along the way. We also wanted to use the bus as a memorial to Justin's father,
              Jack, to remember him and use his nickname as the name for the bus. So, whatever bus we ended
              up with, was to be named Alfie! Finally, we found one not far away in Sunnyvale and went to check
              it out. After seeing that the bus was structurally sound, despite having a completely blown engine,
              we decided to purchase it. After a title in hand was obtained by the seller and a few paperwork issues
              were addressed, we rented a uHaul truck and trailer and brought it home!
            </p>
          </Col>
        </Row>

        <Row style={{ padding: 20 }}>
          <Col md={8}>
            <h4>It Begins</h4>
            <ColoredLine color="white" />
            <p>
              After getting the vehicle home, it was a very exciting time, but unfortunately the bus still was not
              in running condition, desperately needed some new tires, and a bunch of other work to be done.
              Considering that we knew we needed a new engine, we researched the popular Subaru engine swap as
              opposed to replacing it with a rebuilt Type-4 stock engine. For no other reasons than reliability
              and an overall upgrade to the bus, we located a shop up in the Richmond area that took on the
              conversion and install of the Subaru engine. It was a costly endeavour but an investment worth
              making. We finally had our bus back and driveable!
            </p>
          </Col>
          <Col md={4}>
            <img className="rounded" src={require('../.././assets/img/bus.jpg')} style={{ maxWidth: "100%" }}
              alt="The bus shortly after we bought it!" />
          </Col>
        </Row>

        <Row style={{ padding: 20 }}>
          <Col>
            <h4>The Road to Roadshow</h4>
            <ColoredLine color="white" />
            <p>
              After watching a long-time friend perform comedy over the past 5 years, Justin was really interested
              in trying it out. He had been writing ideas down for a long time but had not actively pursued putting
              a "tight five" together yet. A new co-worker introduced him to a comedy writing meetup group that met
              on weekends in Santa Clara and this helped kick start the serious writing, practicing, and performing
              that soon followed. In a short period of time, Justin found a true passion for stand up comedy and
              really enjoyed the challenge it posed. He quickly noticed the trends in the comedy scene in the
              south bay area and wanted to do something different to change it up, and also give the local comics
              another opportunity for stage time. We realized that the bus could also double as the perfect mobile
              vessel to transport a comedy show to any available location, while also providing a unique backdrop as
              opposed to the traditional red curtain. We started planning, and executing very quickly..
            </p>
          </Col>
        </Row>

        <Row style={{ padding: 20 }}>
          <Col>
            <h4>The Mobile Comedy Club</h4>
            <ColoredLine color="white" />
            <p>
              Most all items purchased for the show were found on Amazon. What a wonderful world we live in these
              days, eh? We built our own stage from oak and douglas fir wood purchased at our local Lowes, and
              had a lot of help from the rockstar Consensio (shout out to you my man) who cut all our wood as we
              did not have a saw at home. With all that now ready to be assembled, we got to work. It took roughly
              2 days to build the stage (constructing, sanding, staining, drying, sealing, etc..) with the thought
              f keeping it as mobile and manageable as possible. We found a graphic designer to create our logo and
              music composer for our intro and outro music off the website Fiverr, and we had our logo printed
              onto a vinyl sticker at FedEx. We placed that down on the top of the stage before applying the
              sealant and voila, a stage! We had everything we needed at this point, except a place to host our
              show! Luckily, the owners over at Das Brew Taproom in Fremont were kind enough to let us be part
              of their annual Oktoberfest and we hit the ground running with preparations.
            </p>
            <div className="text-center">
              <Button variant="success" href="https://forms.gle/6o4x9D5VHXfzMLo99" size="lg" style={{ fontSize: "15px" }}
                target='_blank' rel="noreferrer">Book A Show With Us!
              </Button>
            </div>
          </Col>
        </Row>

      </Row>

    </Container>
  )
}